import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import { 
  Backdrop, Box, CircularProgress, IconButton, Typography, useTheme 
} from "@mui/material";
import { useEffect, useState } from "react";
import BarChart from "../../components/BarChart";
import Header from "../../components/Header";
import PieChart from "../../components/PieChart";
import StatBox from "../../components/StatBox";
import { greenColor } from "../../config/colors";
import { useCookiesLogin } from "../../config/cookies";
import { HOST_SINGED } from "../../config/hosts";
import { tokens } from "../../theme";

const Dashboard = () => {
  const { cookies } = useCookiesLogin();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [cookiesLoaded, setCookiesLoaded] = useState(false); // New state for cookies loading
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [clients, setClients] = useState(0);
  const [proposals, setProposals] = useState([]);
  const [proposalStats, setProposalStats] = useState({
    approved: [],
    rejected: [],
    open: [],
    finished: [],
  });
  const [proposalValues, setProposalValues] = useState({
    approved: 0,
    rejected: 0,
    open: 0,
    finished: 0,
  });
  const [quantityCoins, setQuantityCoins] = useState(0);
  const [dataLoaded, setDataLoaded] = useState({
    proposals: false,
    clients: false,
    coins: false
  });

  useEffect(() => {
    document.title = "Home";
  }, []);

  const categorizeProposals = (data) => {
    const categorized = {
      approved: data.filter(p => p.fields.status === "APPROVED"),
      rejected: data.filter(p => p.fields.status === "REJECTED"),
      open: data.filter(p => p.fields.status === "CREATED"),
      finished: data.filter(p => p.fields.status === "FINISHED"),
    };
    setProposalStats(categorized);
  };

  const calculateProposalValues = () => {
    const values = Object.fromEntries(
      Object.entries(proposalStats).map(([key, proposals]) => [
        key, proposals.reduce((sum, p) => sum + parseFloat(p.fields.amount_total || 0), 0)
      ])
    );
    setProposalValues(values);
  };

  const fetchData = async (url, setter, dataKey) => {
    try {
      const response = await fetch(url, { headers: { "Content-Type": "application/json" } });
      if (response.ok) {
        const data = await response.json();
        setter(data);
        setDataLoaded(prev => ({ ...prev, [dataKey]: true }));
      } else {
        setHasError(true);
      }
    } catch (error) {
      console.error("Fetch error:", error);
      setHasError(true);
    }
  };

  useEffect(() => {    
    if (!cookies.BRAUM_TOKEN) return;

    setCookiesLoaded(true); // Set cookiesLoaded to true when the cookies are available
    setIsLoading(true);
    setHasError(false);

    const { role, bussines_id } = cookies.BRAUM_TOKEN;
    let proposalUrl, clientUrl;

    if (role === "admin") {
      proposalUrl = `${HOST_SINGED}/reduto/proposal/all`;
      clientUrl = `${HOST_SINGED}/reduto/client/all`;
    } else if (role === "consultant") {
      proposalUrl = `${HOST_SINGED}/reduto/proposal/business/${bussines_id}`;
      clientUrl = `${HOST_SINGED}/reduto/business/clients/${bussines_id}`;
    } else {
      console.error("Invalid role");
      setHasError(true);
      return;
    }

    fetchData(proposalUrl, (data) => {
      setProposals(data);
      categorizeProposals(data);
    }, "proposals");

    fetchData(clientUrl, (data) => {
      setClients(data.length);
    }, "clients");

    if (bussines_id) {
      fetchData(`${HOST_SINGED}/reduto/consult/coins/${bussines_id}`, (data) => {
        setQuantityCoins(data.quantity || 0);
      }, "coins");
    } else {
      setDataLoaded(prev => ({ ...prev, coins: true }));
    }
  }, [cookies.BRAUM_TOKEN]);

  useEffect(() => {
    calculateProposalValues();
  }, [proposalStats]);

  useEffect(() => {
    // Check if all required data has been loaded
    if (Object.values(dataLoaded).every(Boolean)) {
      setIsLoading(false);
    }
  }, [dataLoaded]);

  // Check if cookies are loaded and valid before rendering
  if (!cookiesLoaded || !cookies.BRAUM_TOKEN) {
    return (
      <Backdrop open={true} style={{ zIndex: 9999 }}>
        <CircularProgress sx={{ color: greenColor }} />
      </Backdrop>
    );
  }

  if (isLoading) {
    return (
      <Backdrop open={true} style={{ zIndex: 9999 }}>
        <CircularProgress sx={{ color: greenColor }} />
      </Backdrop>
    );
  }

  if (hasError) {
    return (
      <Box m="20px">
        <Header title="DASHBOARD" subtitle="Seja bem-vindo ao painel de controle" />
        <Typography color="error">Erro ao carregar dados. Tente novamente.</Typography>
      </Box>
    );
  }

  

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Seja bem-vindo ao painel de controle" />
      </Box>
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="140px" gap="20px">
        <Box gridColumn="span 6" gridRow="span 3" backgroundColor={colors.primary[400]}>
          <Box mt="25px" p="0 30px" display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
                Propostas na plataforma
              </Typography>
              <Typography variant="h2" fontWeight="bold" color={colors.greenAccent[500]}>
                {Object.values(proposalValues).reduce((sum, val) => sum + val, 0).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2
                })}
              </Typography>
            </Box>
            <IconButton>
              <DownloadOutlinedIcon sx={{ fontSize: "36px", color: colors.greenAccent[500] }} />
            </IconButton>
          </Box>
          <Box height="350px" m="20px 0 0 0">
            <PieChart isDashboard data={Object.values(proposalStats)} />
            <BarChart isDashboard data={Object.values(proposalStats)} />
          </Box>
        </Box>
        <Box gridColumn="span 3" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center" m="0 0 10px 0">
          <StatBox title={proposals.length} subtitle="Propostas" progress={proposalStats.approved.length / proposals.length || 0} increase={`${(proposalStats.approved.length / proposals.length * 100 || 0).toFixed(2)}% aprovadas`} icon={<PointOfSaleIcon sx={{ color: colors.greenAccent[400], fontSize: "36px" }}/>}/>
        </Box>
        <Box gridColumn="span 3" backgroundColor={colors.primary[400]} display="flex" alignItems="center" m="0 0 10px 0">
          <StatBox title={clients} subtitle="Clientes Cadastrados" progress="0.5" fontSize={30} increase="0%" icon={<PersonAddIcon sx={{ color: colors.greenAccent[400], fontSize: "36px" }}/>}/>
        </Box>
        <Box gridColumn="span 6" backgroundColor={colors.primary[400]} display="flex" flexDirection="column" justifyItems="start" justifyContent="center" height={300}>
          <ContentPasteSearchIcon sx={{ color: colors.greenAccent[400], fontSize: "36px", m: "0 0 0 30px" }} />
          <Typography variant="h2" color={colors.greenAccent[400]} fontWeight="bold" fontSize={46} sx={{ m: "0 0 0 30px" }}>
            RED SCORE
          </Typography>
          <Typography variant="h2" color={colors.grey[100]} fontWeight="bold" fontSize={46} sx={{ m: "0 0 0 30px" }}>
            {quantityCoins}
          </Typography>
          <Typography variant="h4" color={colors.grey[100]} fontWeight="bold" sx={{ m: "0 0 0 30px" }}>
            Consultas Restantes
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
