import { useSearchParams } from "react-router-dom";

const Payments = () => {

    // verificar token



    const [searchParams] = useSearchParams();
    const myParam = searchParams.get("status");
    
    return <div><div>O valor do parâmetro "param" é: {myParam}</div></div>
}
export default Payments;