import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, useTheme, Backdrop, CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import { useCookiesLogin } from '../../config/cookies';
import { HOST_BRAUM, HOST_SINGED } from '../../config/hosts';
import { tokens } from '../../theme';

import FindInPageIcon from '@mui/icons-material/FindInPage';
import { formatCNPJ } from '../redscore';
import { handleSerasaScore } from "../redsimulation/createConsulting";

export const description = "A multiple bar chart";


const ConsultList = ({ data }) => {    
    const [documentFilter, setDocumentFilter] = useState('');
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    // usestates calling apis and save the data
    const [consults, setConsults] = useState([]);
    const [clientInfo, setClientInfo] = useState([]);
    const [filterRows, setFilteredRows] = useState([]);// Sempre inicializar como array
    const [loading, setLoading] = useState(true);
    const { cookies } = useCookiesLogin();
    const { bussines_id } = cookies?.BRAUM_TOKEN || {};

    // usestates functions
    const [dateRange, setDateRange] = useState([null, null]);
    const [filteredShow, setFilterShow] = useState(false);
    const [showDocumentFilter, setShowDocumentFilter] = useState(false);

    const [pg, setPg] = React.useState(0);
    const [rpg, setRpg] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPg(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRpg(parseInt(event.target.value, 10));
        setPg(0);
    };

    const clearFilters = () => {
        setDateRange([null, null]);
        setDocumentFilter('');
    };


    function filterByDocument(data, document) {
        if (document === "") {
            setFilterShow(false)
        } else {
            let filter = data.filter(item => formatCNPJ(item.document) === formatCNPJ(document));
            setFilteredRows(filter)
            setFilterShow(true);
        }
        
    }

    const handleShowDocumentFilter = () => {
        setShowDocumentFilter(!showDocumentFilter);
        setFilterShow(false);
    };

    const handleQuickSelect = (days) => {
        clearFilters();
        const today = dayjs();
        setDateRange([today.subtract(days, 'day'), today]);
    };

    useEffect(() => {
        async function fetchConsults() {
            try {
                setLoading(true);
                const res = await fetch(`${HOST_SINGED}/reduto/consult/business/${bussines_id}`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
    
                const resultado = await res.json();
    
                // Processando todas as consultas em paralelo
                const updatedConsults = await Promise.all(
                    resultado.consults.map(async (data) => {
                        try {
                            // Requisição para buscar dados do usuário no banco de dados do Braum
                            const userRes = await fetch(`${HOST_BRAUM}/users/infos/${data.user_id}`, {
                                headers: {
                                    "Content-Type": "application/json",
                                },
                            });
                            const userData = await userRes.json();
    
                            // Requisição para buscar informações do cliente consultado no banco de dados do Singes
                            const clientRes = await fetch(`${HOST_SINGED}/reduto/consult/clientinfo/${data.consult_id}`, {
                                headers: {
                                    "Content-Type": "application/json",
                                },
                            });
                            const clientData = await clientRes.json();
    
                            // Retornando o objeto atualizado com ambas as informações
                            return {
                                ...data,
                                name: userData.name,
                                clientName: clientData.data.name, // Nova informação do cliente
                            };
                        } catch (error) {
                            console.error("Erro ao buscar informações adicionais:", error);
                            return { ...data, name: "Desconhecido", clientName: "Não encontrado" };
                        }
                    })
                );
    
                setConsults(updatedConsults);
            } catch (error) {
                console.error("Erro ao buscar os dados:", error);
            } finally {
                setLoading(false);
            }
        }
    
        fetchConsults();
    }, [bussines_id]);
    

    useEffect(() => {
    }, [filteredShow]);
    return (
        <>
            <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
                    <Box p={10} m={2} fullWidth={true}>
                    <Header title="Reduto Score consultas" subtitle="Valorize suas consultas realizadas e acompanhe os resultados!" />

                    <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
                        <Button onClick={handleShowDocumentFilter} style={{ color: "#c5c5c5" }}>
                                <FindInPageIcon sx={{ color: colors.greenAccent[400], marginRight: 1, fontSize: 30 }} />
                            CPF / CNPJ
                        </Button>
                    </Box>

                    {showDocumentFilter && (
                        <Box sx={{ display: 'flex', marginBottom: 2,}}>

                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    margin: "15px 0px"
                            }}>
                                    <TextField
                                        fullWidth
                                        label="CPF/CNPJ"
                                        variant="outlined" // Mudança para "outlined" para melhorar a aparência
                                        value={documentFilter}
                                        onChange={(e) => {
                                            setDocumentFilter(e.target.value);
                                        }}
                                        InputProps={{
                                            sx: { // Fundo claro
                                                borderRadius: "8px", // Bordas arredondadas
                                                "&:hover": { // Fundo ao passar o mouse
                                                },
                                            },
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                fontSize: "16px",
                                                color: "#666", // Cor do label
                                                "&.Mui-focused": {
                                                    color: "#4caf50", // Cor quando está focado
                                                },
                                            },
                                        }}
                                        sx={{
                                            marginBottom: "10px",
                                            marginRight: "10px",
                                        }}
                                    />
                                <Button
                                    sx={{
                                        width: 150,
                                        height: 40,
                                        marginY: 0,
                                        fontSize: 10
                                        }}
                                        onClick={() => filterByDocument(consults, documentFilter)}
                                    color="secondary"
                                    variant="outlined"
                                >
                                    Consultar cliente
                                </Button>
                            </Box>
                        </Box>
                    )}
                    <TableContainer>
                        <Table>
                            <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontSize: '18px', fontWeight: 'bold', color: colors.greenAccent[400] }}>SCORE AGRO</TableCell>
                                        <TableCell sx={{ fontSize: '18px', fontWeight: 'bold', color: colors.greenAccent[400] }}>Responsável pela consulta</TableCell>
                                        <TableCell sx={{ fontSize: '18px', fontWeight: 'bold', color: colors.greenAccent[400] }}>Nome do Cliente</TableCell>
                                        <TableCell sx={{ fontSize: '18px', fontWeight: 'bold', color: colors.greenAccent[400] }}>Documento</TableCell>
                                        <TableCell sx={{ fontSize: '18px', fontWeight: 'bold', color: colors.greenAccent[400] }}>Data de Criação</TableCell>
                                    </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    filteredShow === true ? <ConsultsWithFilter data={filterRows} clientData={clientInfo} pg={pg} rpg={rpg} /> : <ConsultsNoFilter data={consults} clientData={clientInfo} pg={pg} rpg={rpg} />
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={consults.length}
                        rowsPerPage={rpg}
                        page={pg}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>

        </>

    );
};

const ConsultsNoFilter = ({ data, clientData, pg, rpg }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    if (!data || data.length === 0) {
        return (
            <TableRow>
                <TableCell colSpan={5} align="center" sx={{ fontSize: '18px', fontWeight: 'bold', color: "#c2c2c2" }}>
                    Dados indisponíveis
                </TableCell>
            </TableRow>
        );
    }

    return data.slice(pg * rpg, pg * rpg + rpg).map((row) => (
        <TableRow key={row.id}>
            <TableCell sx={{ fontSize: '22px', fontWeight: 'bold', color: colors.greenAccent[400] }}>{row.score}</TableCell>
            <TableCell sx={{ fontSize: '17px', fontWeight: 'bold', color: "#c2c2c2" }}>{row.name}</TableCell>
            <TableCell sx={{ fontSize: '17px', fontWeight: 'bold', color: "#c2c2c2" }}>{row.clientName.toUpperCase()}</TableCell>
            <TableCell sx={{ fontSize: '17px', fontWeight: 'bold', color: "#c2c2c2" }}>{formatCNPJ(row.document)}</TableCell>
            <TableCell sx={{ fontSize: '17px', fontWeight: 'bold', color: "#c2c2c2" }}>{dayjs(row.created_at).format('DD/MM/YYYY HH:mm')}</TableCell>
        </TableRow>
    ));
};


const ConsultsWithFilter = ({ data, clientData, pg, rpg }) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
            data.slice(pg * rpg, pg * rpg + rpg).map((row) => (
                <TableRow key={row.id}>
                    <TableCell sx={{ fontSize: '22px', fontWeight: 'bold', color: colors.greenAccent[400] }}>{row.id}</TableCell>
                    <TableCell sx={{ fontSize: '17px', fontWeight: 'bold', color: "#c2c2c2" }}>{row.name}</TableCell>
                    <TableCell sx={{ fontSize: '18px', fontWeight: 'bold' }}>row.clientName</TableCell>
                    <TableCell sx={{ fontSize: '17px', fontWeight: 'bold', color: "#c2c2c2" }}>{formatCNPJ(row.document)}</TableCell>
                    <TableCell sx={{ fontSize: '17px', fontWeight: 'bold', color: "#c2c2c2" }}>{dayjs(row.created_at).format('DD/MM/YYYY HH:mm')}</TableCell>
                </TableRow>
            ))
    )
}

export default ConsultList;
