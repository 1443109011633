import { allRoutes } from "./allRoutes";

export class RouteAccess {
  constructor(access) {
    const obj = {
      admin: this.admin(),
      consultant: this.consultant(),
      user: this.user(),
      "": [],
    };
    return obj[access];
  }

  admin() {
    return [
      allRoutes.home,
      allRoutes.team,
      allRoutes.contacts,
      allRoutes.form,
      allRoutes.formSucessBussines,
      allRoutes.formSucessClient,
      allRoutes.red25,
      allRoutes.redtri,
      allRoutes.redsafra,
      allRoutes.proposal,
      allRoutes.profile,
      allRoutes.createUser,
      allRoutes.clientTable,
      allRoutes.businessTable,
      allRoutes.redscore, 
      allRoutes.redrural,
      allRoutes.redCoins,
      allRoutes.redConsultsList,
      allRoutes.createUserWithoutConsult,
      allRoutes.payments
    ];
  }

  consultant() {
    return [
      allRoutes.home,
      allRoutes.red25,
      allRoutes.redtri,
      allRoutes.redsafra,
      allRoutes.form,
      allRoutes.formSucessBussines,
      allRoutes.formSucessClient,
      allRoutes.proposal,
      allRoutes.profile,
      allRoutes.createUser,
      allRoutes.clientTable,
      allRoutes.redscore,
      allRoutes.redrural,
      allRoutes.redCoins,
      allRoutes.redConsultsList,
      allRoutes.payments
    ];
  }

  user() {
    return [allRoutes.homeUser, allRoutes.proposalUser, allRoutes.profile ];
  }
}
