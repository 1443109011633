import Stripe from "stripe";
import { configStripe } from "./config/configurations";


export const stripe = new Stripe("sk_test_51Qb8kj03RPBQjdXjip0FX6VPdJ9ayE5jbLQLCqKHAtv9EVQPV6f3Vo3rRDzqVEhljYihoRBDh2uTGpkQiymTxIkt00Pn0JYQWh", {
    apiVersion: '2025-02-24.acacia',
    httpClient: Stripe.createFetchHttpClient(),
})

export const getStripeCustomerByEmail = async (email: string) => {
    const customers = await stripe.customers.list({ email });
    return customers.data[0];
}

export const createStripeCustomer = async (
    input: {
        name?: string,
        email: string
    }
) => {
    let customer = await getStripeCustomerByEmail(input.email)
    if (customer) return customer
    const createdCustomer = await stripe.customers.create({
        email: input.email,
        name: input.name
    });
    await stripe.subscriptions.create({
        customer: createdCustomer.id,
        items: [
            { price: configStripe.stripe.stripeRedutoProductPriceSubscribe }
        ]
    });
}

export const createCheckoutSessionCard = async (userId: string, userEmail: string, token: string) => {
    try {
        let customer = await createStripeCustomer({
            email: userEmail
        })

        let sts = btoa(token)
        const session = await stripe.checkout.sessions.create({
            payment_method_types: ['card'],
            mode: 'payment',
            client_reference_id: userId,
            customer: customer?.id,
            success_url: `${process.env.HOST_REDUTO}/app/redutoconsultas?status=true&sec=${sts}`,
            cancel_url: `${process.env.HOST_REDUTO}/app/redutoconsultas`,
            line_items: [{
                price_data: {
                    currency: 'brl',
                    product_data: {
                        name: 'Reduto Capital - Fichas',
                    },
                    unit_amount: 4500,
                },
                quantity: 10,
            },],
        });
        console.log(session, "SESSION")
        return {
            url: session.url
        }
    } catch (error) {
        console.log(error);
        throw new Error('Error to create checkout session')
    }
}

export const createCheckoutSessionBoleto = async (userId: string, userEmail: string, token: string) => {
    try {
        let customer = await createStripeCustomer({
            email: userEmail
        })
        let sts = btoa(token)
        const session = await stripe.checkout.sessions.create({
            payment_method_types: ['boleto'],
            mode: 'payment',
            client_reference_id: userId,
            payment_method_options: {
                boleto: {
                    expires_after_days: 10 
                }
            },
            customer: customer?.id,
            success_url: `${process.env.HOST_REDUTO}/app/redutoconsultas?status=true&sec=${sts}`,
            cancel_url: `${process.env.HOST_REDUTO}/app/redutoconsultas`,
            line_items: [{
                price_data: {
                    currency: 'brl',
                    product_data: {
                        name: 'Reduto Capital - Fichas',
                    },
                    unit_amount: 4500,
                },
                quantity: 10,
            },],
        });
        console.log(session, "SESSION")
        return {
            url: session.url
        }
    } catch (error) {
        console.log(error);
        throw new Error('Error to create checkout session')
    }
}

export const handleProcessWebhookCheckout = async (event: { object: Stripe.Checkout.Session }) => {
    const clientReferenceId = event.object.client_reference_id as string
    const stripeSubscriptionId = event.object.subscription as string
    const stripeCustomerId = event.object.customer as string
    const checkoutStatus = event.object.status
    if (checkoutStatus !== 'complete') return
    if (!clientReferenceId || !stripeSubscriptionId || !stripeCustomerId) {
        throw new Error('clientReferenceId, stripeSubscriptionId and stripeCustomerId is required')
    }
    // const userExists = await prisma.user.findUnique({
    //     where: {
    //         id: clientReferenceId
    //     },
    //     select: {
    //         id: true
    //     }
    // })
    // if (!userExists) {
    //     throw new Error('user of clientReferenceId not found')
    // }
    // await prisma.user.update({
    //     where: {
    //         id: userExists.id
    //     },
    //     data: {
    //         stripeCustomerId,
    //         stripeSubscriptionId
    //     }
    // })
}

export const handleProcessWebhookUpdatedSubscription = async (event: { object: Stripe.Subscription }) => {
    const stripeCustomerId = event.object.customer as string
    const stripeSubscriptionId = event.object.id as string
    const stripeSubscriptionStatus = event.object.status
    // const userExists = await prisma.user.findFirst({
    //     where: {
    //         OR: [
    //             {
    //                 stripeSubscriptionId,
    //             },
    //             {
    //                 stripeCustomerId
    //             }
    //         ]
    //     },
    //     select: {
    //         id: true
    //     }
    // })
    // if (!userExists) {
    //     throw new Error('user of stripeCustomerId not found')
    // }
    // await prisma.user.update({
    //     where: {
    //         id: userExists.id
    //     },
    //     data: {
    //         stripeCustomerId,
    //         stripeSubscriptionId,
    //         stripeSubscriptionStatus,
    //     }
    // })
}