/* eslint-disable array-callback-return */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DocumentScannerRoundedIcon from '@mui/icons-material/DocumentScannerRounded';
import PixRoundedIcon from '@mui/icons-material/PixRounded';
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import * as yup from "yup";
import { useCookiesLogin } from "../../config/cookies";

import { Formik } from "formik";
import { toast } from "react-toastify";
import { greenColor } from "../../config/colors";
import { HOST_SINGED } from "../../config/hosts";
import { createCheckoutSessionBoleto, createCheckoutSessionCard, createStripeCustomer, getStripeCustomerByEmail } from "./stripe/index";

import { Backdrop, Button, CircularProgress, Typography } from "@mui/material";
import { useSearchParams } from 'react-router-dom';
import Header from "../../components/Header";
import Resume from "./resume";
import PixQRCode from './qrcode';


const sanitazeUser = async (name, email, token) => {
  

  try {
    const customer = undefined;
    let verifyCustomer = await getStripeCustomerByEmail(email);

    if (!verifyCustomer) {
      console.log("Customer not exists!");
    } else {
      const customer = await createStripeCustomer(name, email);

      return customer;
    }
  } catch (err) {
    console.log(err, "ERRO")
    alert("Houve algum erro no momento do pagamento, contate o suporte!")
  }
}


const RedCoins = () => {
  const { cookies } = useCookiesLogin();
  const [isLoading, setIsLoading] = useState(false);

  const { id, bussines_id, name, email } = cookies.BRAUM_TOKEN;
  const [tokenSerasa, setTokenSerasa] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [coinsPrice, setCoinsPrice] = useState(45);
  const [resume, setResume] = useState(false);
  const [resumePayment, setResumePayment] = useState(false);
  const [paymentResumeId, setPaymentResumeId] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const [searchParams] = useSearchParams();
  const myParam = searchParams.get("status");

  useEffect(() => {
    if (myParam === true) {
      alert("Parabéns você adquiriu novas fichas!");
    }
  }, [myParam])

  const initialValues = {
    quantity: "",
  };

  const handleCardClick = (quantity) => {
    const newTotal = quantity * coinsPrice;
    setTotalPrice(newTotal);
    setQuantity(quantity);  
    console.log(newTotal, "newTotal")
    setResume(true);
  };

  const valuesSchema = yup.object().shape({
    quantity: yup
      .number()
      .min(10, "A quantidade mínima é 10")
      .required("Quantidade de fichas é obrigatório"),
  });

  useEffect(() => {
    setResume(false);
    async function getToken() {
      const authSerasa = await fetch(`${HOST_SINGED}/auth/serasa`, {
        method: "GET",
      });
      const returnToken = await authSerasa.json();
      setTokenSerasa(returnToken["token"]);
    }
    getToken();
  }, []);

  useEffect(() => {

  }, [resume])

  async function sendResume() {
    setIsLoading(true);
    setSubmitting(true);

    if (quantity <= 0) {
      toast.info("A quantidade de fichas não pode ser 0", {
        theme: "dark",
      });
      setIsLoading(false);
      return;
    }

    // Prepare request payload

    const requestBody = {
      client_id: id,
      business: bussines_id,
      status_payment: "PAYMENT",
      quantity: quantity,
      total_value: totalPrice,
    };

    try {
      // Make the POST request
      const response = await fetch('https://appsup4tech.com:8000/reduto/request/coins', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (response.ok) {
        setResumePayment(true)
        setPaymentResumeId(data.resume_id)
        toast.success("Fichas solicitadas com sucesso!", {
          theme: "dark",
        });
      } else {
        toast.error("Erro ao solicitar fichas. Tente novamente.", {
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error("Erro de rede ao solicitar fichas.", {
        theme: "dark",
      });
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  }


  return (
    <Box p={5}>
      <Box mt={10}>
        <ToastContainer />

        {resume === false ? (
          <>
            <Header
              title={`SOLICITAR REDSCORE`}
            />
            <Formik
              initialValues={initialValues}
              validationSchema={valuesSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => {
                const totalPrice = values.quantity * coinsPrice || 0;

                const cardData = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

                return (
                  <form onSubmit={handleSubmit}>
                    <Box
                      display="grid"
                      gap="30px"
                      gridTemplateColumns="repeat(2, 1fr)"
                    >
                      {cardData.map((quantity) => (
                        <Box
                          key={quantity}
                          sx={{
                            padding: '20px',
                            backgroundColor: '#1e1e1e',
                            borderRadius: '12px',
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                            transition: 'transform 0.3s ease-in-out',
                            '&:hover': {
                              transform: 'scale(1.05)',
                            },
                          }}
                        >
                          <Typography variant="h5" gutterBottom>
                            {quantity} consultas
                          </Typography>
                          <Typography variant="body1" gutterBottom sx={{
                            fontSize: 24,
                            color: "#83d84d"
                          }}>
                            {(quantity * coinsPrice).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                          </Typography>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleCardClick(quantity)}
                            sx={{
                              mt: 2,
                              backgroundColor: '#83d84d',
                              '&:hover': {
                                backgroundColor: '#6bb03a',
                              },
                            }}
                          >
                            Solicitar
                          </Button>
                        </Box>
                      ))}
                    </Box>
                  </form>
                );
              }}
            </Formik>
          </>
        ) : (
          <>
            <Box mt={1} mb={3} sx={{ display: 'flex' }}>
              <Button
                onClick={() => { 
                  setResume(false) 
                  setResumePayment(false)
                }}
                startIcon={<ArrowBackIcon />}
                sx={{
                  color: '#fff',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <Typography fontSize={17} fontWeight={400} marginX={1}>
                  Voltar
                </Typography>
              </Button>
            </Box>

            <Header
              title={`Falta pouco para garantir suas consultas REDSCORE!`}
              subtitle="Selecione a forma de pagamento"
            />

            <Box mt={5} sx={{ textAlign: 'left', backgroundColor: '', padding: '20px', borderRadius: '12px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)' }}>
              <Typography variant="h3" gutterBottom color={'#83d84d'}
              >
                Dados da solicitação
              </Typography>
              <Typography variant="body1" fontSize={17} gutterBottom >
                Quantidade de Fichas: <b>{quantity}</b>
              </Typography>
              <Typography variant="body1" fontSize={17} gutterBottom >
                Valor Total: <b>{totalPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b>
              </Typography>
              {/* <Button
                variant="contained"
                color="primary"
                onClick={sendResume}
                disabled={submitting}
                sx={{
                  mt: 2,
                  backgroundColor: '#83d84d',
                  '&:hover': {
                    backgroundColor: '#6bb03a',
                  },
                }}
              >
                Confirmar Compra
              </Button> */}
            </Box>


            {/* Cards */}
            <Box mt={3} sx={{ display: 'flex', justifyContent: 'space-around' }}>
              <Box
                sx={{
                  padding: '20px',
                  backgroundColor: '#1e1e1e',
                  borderRadius: '12px',
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                  textAlign: 'center',
                  width: '30%',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                <Typography variant="h5" gutterBottom>
                  Cartão de Crédito
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={async () => {
                    const { token } = cookies.BRAUM_TOKEN;
                    // verificar token braum
                    const user = await fetch(`http://localhost:9444/auth/session`, {
                      headers: {
                        "Content-Type": "application/json",
                        "Authorization": token
                      },
                    })
                      
                    const response = await user.json();
                    console.log(response, "response");
                    
                    if (user.status === 200) {
                      const userSanitaze = await sanitazeUser(token);

                      console.log(userSanitaze);
                      let response = await createCheckoutSessionCard(userSanitaze.id, userSanitaze.email, token);
                      if (!response.url) return;
                      window.location.replace(response.url);
                    }
                  }}
                  startIcon={<CreditCardIcon />}
                  sx={{
                    mt: 2,
                    backgroundColor: '#83d84d',
                    fontWeight: 800,
                    '&:hover': {
                      backgroundColor: '#6bb03a',
                    },
                  }}
                >
                  Pagar com Cartão
                </Button>
              </Box>
              <Box
                sx={{
                  padding: '20px',
                  backgroundColor: '#1e1e1e',
                  borderRadius: '12px',
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                  textAlign: 'center',
                  width: '30%',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                <Typography variant="h5" gutterBottom>
                  Boleto
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DocumentScannerRoundedIcon />}
                  sx={{
                    mt: 2,
                    backgroundColor: '#83d84d',
                    fontWeight: 800,
                    '&:hover': {
                      backgroundColor: '#6bb03a',
                    },
                    }}
                    onClick={async () => {
                      const { token } = cookies.BRAUM_TOKEN;
                      // verificar token braum
                      const user = await fetch(`http://localhost:9444/auth/session`, {
                        headers: {
                          "Content-Type": "application/json",
                          "Authorization": token
                        },
                      })

                      const response = await user.json();
                      console.log(response, "response");

                      if (user.status === 200) {
                        const userSanitaze = await sanitazeUser(token);

                        console.log(userSanitaze);
                        let response = await createCheckoutSessionBoleto(userSanitaze.id, userSanitaze.email, token);
                        if (!response.url) return;
                        window.open(response.url, '_blank');
                      }
                    }}
                >
                  Gerar Boleto
                </Button>
              </Box>
              <Box
                sx={{
                  padding: '20px',
                  backgroundColor: '#1e1e1e',
                  borderRadius: '12px',
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                  textAlign: 'center',
                  width: '30%',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                <Typography variant="h5" gutterBottom>
                  PIX
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<PixRoundedIcon />}
                  onClick={() => sendResume()}
                  sx={{
                    mt: 2,
                    backgroundColor: '#83d84d',
                    fontWeight: 800,
                    '&:hover': {
                      backgroundColor: '#6bb03a',
                    },
                  }}
                >
                  Pagar com PIX
                </Button>
              </Box>
            </Box>

            <div>
              {resumePayment === true ? (
                <Resume
                  resumeId={paymentResumeId}
                  email={email}
                  nome={name}
                  quantidade={totalPrice / coinsPrice}
                  valor={totalPrice}
                />
              ) : null}
            </div>
          </>
        )}

        <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
          <CircularProgress color="inherit" sx={{ color: greenColor }} />
        </Backdrop>
      </Box>
    </Box>
  );
};

export default RedCoins;
