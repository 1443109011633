import { useTheme } from "@emotion/react";
import {
  Box,
  Collapse,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import GaugeChart from "react-gauge-chart";
import { useNavigate } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCookiesLogin } from "../../config/cookies";
import { tokens } from "../../theme";
import { ScoreDescription } from "../SerasaScorePj";

const regexCEP = /^(\d{5})(\d{3})$/;
const regexCPF = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
const regexCNPJ = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;
const regexPhone = /^(\d{2})(\d{1})?(\d{4})(\d{4})$/;

const HandlerSerasaSocoreCPF = ({
  dataAPI
}) => {
  // const [basicInfo, setBasicInfo] = useState({})
  // const [serasaAgro, setSerasaAgro] = useState({})
  // const [serasa, setSerasa] = useState({})

  const [partnerParticipation, setPartnerParticipation] = useState();
  const [partnersAndShareholders, setPartnersAndShareholders] = useState();

  const { cookies } = useCookiesLogin();
  const { id, bussines_id } = cookies.BRAUM_TOKEN;

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const navigateToDashboard = () => navigate("/app");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Exemplo de uso do componente:
  const exampleData = [
    {
      typeReg: "R001",
      subtype: "S001",
      name: "Empresa A",
      cnpj: "12.345.678/0001-99",
      level: "Alto",
      sinceDate: "2020-01-01",
      currentDate: "2024-01-01",
      restriction: "S",
      filler: "Informação extra",
    },
    {
      typeReg: "R002",
      subtype: "S002",
      name: "Empresa B",
      cnpj: "98.765.432/0001-11",
      level: "Médio",
      sinceDate: "2019-05-15",
      currentDate: "2023-12-15",
      restriction: "N",
      filler: "Sem informações adicionais",
    },
  ];

  let basicInfo = dataAPI.data.datasets.find(
    (basicInfo) => basicInfo.type === "BASIC_INFO"
  );

  let serasaScore = dataAPI.data.datasets.find(
    (serasaScore) => serasaScore.type === "AGRO_SCORE_CPF"
  );

  let serasa = dataAPI.data.datasets.find(
    (serasaScore) => serasaScore.type === "SERASA"
  );

  console.log(basicInfo)
  return (
    <>
      {dataAPI && (<>
        <ToastContainer />
        <div
          style={{
            marginTop: 50,
          }}
        >
            {/* BASIC_INFO Section */}
            <Typography variant="h3" color={colors.grey[500]} gutterBottom>
              Informações Básicas
            </Typography>
            <Grid container spacing={4} marginBottom={3}>
              <Grid item xs={4} sx={{ marginY: 3 }}>
                <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
                  Nome Oficial
                </Typography>
                <Typography fontSize={22}>
                  {basicInfo.results[0]?.basicData?.name || "Dados Privados"}
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{ marginY: 3 }}>
                <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
                  CPF/CNPJ
                </Typography>
                <Typography fontSize={22}>
                  {basicInfo.results[0]?.basicData?.taxIdNumber === "" ? "Dados Privados" : basicInfo.results[0]?.basicData?.taxIdNumber.replace(regexCPF, "$1.$2.$3-$4")}
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{ marginY: 3 }}>
                <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
                  Situação Cadastral
                </Typography>
                <Typography fontSize={22}>
                  {basicInfo.results[0]?.basicData?.taxIdStatus || "Dados Privados"}
                </Typography>
              </Grid>
            </Grid>
            {/* Endereços */}
            {basicInfo.results[0]?.addresses?.length > 0 && (
              <Typography variant="h4" color={colors.grey[500]} marginTop={3}>
                Endereços
              </Typography>
            )}
            {basicInfo.results[0]?.addresses?.length > 0 && (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontSize: 16 }}>Tipo</TableCell>
                    <TableCell sx={{ fontSize: 16 }}>Logradouro</TableCell>
                    <TableCell sx={{ fontSize: 16 }}>Número</TableCell>
                    <TableCell sx={{ fontSize: 16 }}>Cidade</TableCell>
                    <TableCell sx={{ fontSize: 16 }}>UF</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {basicInfo.results[0].addresses.map((address, index) => (
                    <TableRow key={index}>
                      <TableCell>{address.type || "N/A"}</TableCell>
                      <TableCell>{address.street || "N/A"}</TableCell>
                      <TableCell>{address.number || "S/N"}</TableCell>
                      <TableCell>{address.city || "N/A"}</TableCell>
                      <TableCell>{address.state || "N/A"}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>

        {/* AGRO_SCORE_CNPJ Section */}
        <div
          style={{
            marginTop: 30,
          }}
        >
          <Typography variant="h3" color={colors.grey[500]} gutterBottom>
            Informações para análise de crédito
          </Typography>
          <Grid container spacing={4} marginBottom={3} marginTop={3}>
            <Grid item xs={4} sx={{ marginY: 3 }}>
              <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
                Agro Score
              </Typography>
              <Typography
                color={
                  serasaScore.results[0]?.score < 333 ? "#ff5050" : serasaScore.results[0]?.score < 666 ? "#fff28b" : "secondary"
                }
                fontSize={22}
              >
                {serasaScore.results[0]?.score} de 1000
              </Typography>
              <GaugeChart
                id="gauge-chart2"
                nrOfLevels={20}
                percent={serasaScore.results[0]?.score / 1000}
                textColor="#f1f1f1"
                arcWidth={0.2}
                colors={["#FF0000", "#00FF00"]}
                formatTextValue={(value) => serasaScore.results[0]?.score}
                style={{
                  fontWeight: 600,
                }}
              />
            </Grid>
            <Grid item xs={4} sx={{ marginY: 3 }}>
              <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
                Porcentagem de Risco
              </Typography>
              <Typography fontSize={22}>
                {parseFloat(serasaScore.results[0].event_probability * 100).toFixed(2)} %
              </Typography>
            </Grid>
          </Grid>
          <ScoreDescription />
        </div>

        {/* SERASA Section */}
        {serasa ? <InformacoesSerasa serasaData={serasa}></InformacoesSerasa> : <></>}

        {/* */}
        {console.log(serasa.results[0].partnerParticipation)}
        {console.log(serasa.results[0].partnersAndShareholders)}
        {/* <PartnerParticipationTable partnerParticipationData={serasa.results[0].partnerParticipation} /> */}

        {/* */}
        {/* <PartnersAndShareholdersTable partnersAndShareholdersData={serasa.results[0].partnersAndShareholders} /> */}

      </>)}
    </>
  );
};


const PartnerParticipationTable = ({ partnerParticipationData }) => {
};

const PartnersAndShareholdersTable = ({ partnersAndShareholdersData }) => {
  const [expandedRow, setExpandedRow] = useState(null);
  const toggleRow = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  return (
    <div
      style={{
        marginTop: 30,
      }}
    >
      {partnersAndShareholdersData.length > 0 ? (
        <>
          <Typography variant="h3" gutterBottom>
            Sócios e Acionistas
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>CNPJ/CPF</TableCell>
                  <TableCell>Tipo de Pessoa</TableCell>
                  <TableCell>Capital Total</TableCell>
                  <TableCell>Restrição</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {partnersAndShareholdersData.map((entry, index) => (
                  <React.Fragment key={index}>
                    <TableRow
                      onClick={() => toggleRow(index)}
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          expandedRow === index ? "#f9f9f9" : "inherit",
                      }}
                    >
                      <TableCell>{entry.name}</TableCell>
                      <TableCell>{entry.cnpjCpf}</TableCell>
                      <TableCell>{entry.personType}</TableCell>
                      <TableCell>{entry.capital}</TableCell>
                      <TableCell>{entry.restriction}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={5} style={{ padding: 0 }}>
                        <Collapse
                          in={expandedRow === index}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box margin={2}>
                            <Typography variant="body1" gutterBottom>
                              Detalhes do Registro
                            </Typography>
                            <ul>
                              <li>
                                <strong>Tipo do Registro:</strong> {entry.typeReg}
                              </li>
                              <li>
                                <strong>Subtipo do Registro:</strong> {entry.subtype}
                              </li>
                              <li>
                                <strong>Situação do Capital Total:</strong>{" "}
                                {entry.companySituation}
                              </li>
                              <li>
                                <strong>Código Socio Consistido:</strong>{" "}
                                {entry.situation}
                              </li>
                              <li>
                                <strong>Filler:</strong> {entry.filler}
                              </li>
                            </ul>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      
      ): null}
      
    </div>
  );
};

// IA USED
export const InformacoesSerasa = ({ serasaData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [expandedRow, setExpandedRow] = useState(null);

 
  const dataEntries = Object.entries(serasaData.results[0].data).map(
    ([type, details]) => ({
      type,
      ocorrencias: details.occurrences,
      quantidade: details.valueOfOccurrences || "0.00",
      details: details,
    })
  );

  const toggleRow = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  }

  function translaterKeys(key) {
    switch(key) {
      case "finalDate": return "Data Final"
      case "value": return "Valor"
      case "totalQuantity": return "Quantidade Total"
      case "protest": return "Protesto"
      case "convem": return "Convem"
      case "judicialAction": return "Ações Judiciais"
      case "financialRestrictions": return "Restrições Financeiras"
      case "bankruptcies" : return "Falências"
      case "acheiRecheque": return "Achei Recheque"
      case "ccf": return "Cheques sem Fundo"
      case "financialPendencies": return "Pendências Financeiras"
      case "documentsAlert": return "Documentos em Alerta"
    }
  }

  function translaterKeysOcurrences(key) {
    if (key === "finalDate") return "Data final"
    if (key === "value") return "Valor"
    if (key === "totalQuantity") return "Quantidade total"
    if (key === "typeReg") return "Tipo"
    if (key === "subtype") return "Sub Tipo"
    if (key === "occurrenceDate") return "Data da ocorrência."
    if (key === "bank") return "Banco"
    if (key === "agency") return "Agência"
    if (key === "checkqQty") return "Quantidade"
    if (key === "praca") return "Praca"
    if (key === "state") return "Status"
    if (key === "bankName") return "Nome banco"
    if (key === "city") return "Cidade"
    if (key === "nature") return "Natureza"
    if (key === "branchCnpj") return "Filial CNPJ"
    if (key === "docDig") return "Documento digital"
    if (key === "inclusionDate") return "Data da inclusão"
    if (key === "inclusionHour") return "Hora da inclusão"
    if (key === "filler") return "Filler"
  }

  return (
    <div style={{ marginTop: 30 }}>
      <Typography variant="h3" color={colors.grey[500]} gutterBottom>
        Informações de Crédito
      </Typography>
      <TableContainer component={Paper} elevation={3} style={{ borderRadius: 10 }}>
        <Table>
          <TableHead style={{ backgroundColor: colors.primary[500] }}>
            <TableRow>
              <TableCell style={{ color: colors.grey[100], fontWeight: 'bold', fontSize: 18 }}>Tipo</TableCell>
              <TableCell style={{ color: colors.grey[100], fontWeight: 'bold', fontSize: 18 }}>Ocorrências</TableCell>
              <TableCell style={{ color: colors.grey[100], fontWeight: 'bold', fontSize: 18 }}>Número de ocorrências</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataEntries.slice(0, -3).map((entry, index) => (
              <React.Fragment key={index}>
                <TableRow
                  onClick={() => toggleRow(index)}
                  style={{
                    cursor: entry.ocorrencias > 0 ? "pointer" : "default",
                    backgroundColor: expandedRow === index ? colors.grey[200] : "inherit",
                    transition: 'background-color 0.3s ease',
                  }}
                >
                  <TableCell style={{ fontSize: 16 }}>{translaterKeys(entry.type)}</TableCell>
                  <TableCell style={{ fontSize: 16 }}>{entry.ocorrencias}</TableCell>
                  <TableCell style={{ fontSize: 16 }}>{entry.quantidade}</TableCell>
                </TableRow>
                {entry.ocorrencias > 0 && (
                  <TableRow>
                    <TableCell colSpan={3} style={{ padding: 0 }}>
                      <Collapse in={expandedRow === index} timeout="auto" unmountOnExit>
                        <Box margin={2} style={{ backgroundColor: colors.grey[50], borderRadius: 10, padding: 16 }}>
                          <Typography variant="h4" gutterBottom>
                            Detalhes:
                          </Typography>
                          <ul>
                            {(entry.details.acheiRechequeDetails ||
                              entry.details.acheiRecheque ||
                              entry.details.protestsDetails ||
                              entry.details.judicialAction ||
                              entry.details.convem ||
                              entry.details.financialRestrictions ||
                              entry.details.bankruptcies ||
                              entry.details.financialPendencies ||
                              entry.details.documentsAlert ||
                              entry.details.ccfDetails ||
                              []
                            ).map((detail, detailIndex) => (
                              <li key={detailIndex} style={{ marginBottom: 8 }}>
                                {Object.entries(detail).map(([key, value]) => (
                                  <div key={key} style={{ marginBottom: 4, fontSize: 16 }}>
                                    <strong>{translaterKeysOcurrences(key)}:</strong> {value}
                                  </div>
                                ))}
                              </li>
                            ))}
                          </ul>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default HandlerSerasaSocoreCPF;