import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router";
import { useCookiesLogin } from "../../config/cookies";
import { logout } from "../../redux/actions";
import store from "../../redux/store";
import { ColorModeContext, tokens } from "../../theme";
import { HOST_SINGED } from "../../config/hosts";
import { useEffect, useState } from "react";


const Topbar = () => {
  const { cookies, removeCookie } = useCookiesLogin();

  const { id, bussines_id, name, email } = cookies.BRAUM_TOKEN;

  const [quantityCoins, setQuantityCoins] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  

  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  // Realizando o logout:
  const handleLogout = () => {
    store.dispatch(logout());
    removeCookie("BRAUM_TOKEN");


    navigate("/");
  };

  useEffect(() => {
      setIsLoading(true);
      fetch(`${HOST_SINGED}/reduto/consult/coins/${bussines_id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (res) => {
          const requestStatus = await res.json();
  
          console.log(requestStatus, "RESPONSE");
          console.log(res.status, "STATUS RESPONSE");
  
          if (res.status === 200) {
            setQuantityCoins(requestStatus.quantity)
          } else {
            setQuantityCoins(0);
          }
        })
    }, [quantityCoins]);    

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        {/* <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton> */}
      </Box>

      {/* ICONS */}
      <Typography variant="h4">
        Fichas Redscore: {quantityCoins}
      </Typography>
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            navigate("profile")
          }}
        >
          <PersonOutlinedIcon />
        </IconButton>
        <IconButton
          onClick={(e) => {
            //store.dispatch(logout());
            //removeCookie("BRAUM_TOKEN");
            
            removeCookie("BRAUM_TOKEN");
            localStorage.clear();
            navigate("/", { replace: true });
            window.location.reload(true)
          }}
        >
          <ExitToAppIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
