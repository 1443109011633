import React, { useEffect } from "react";
import SidebarAdmin from "../../scenes/global/SidebarAdmin";
import SidebarConsultant from "../../scenes/global/SidebarConsultant";
import SidebarUser from "../../scenes/global/SidebarUser";

export default function SidebarManager({ access, isSidebar }) {
  useEffect(() => {
    console.log('Carregou sidebar manager')
  })

  const options = {
    admin: <SidebarAdmin isSidebar={isSidebar} />,
    consultant: <SidebarConsultant isSidebar={isSidebar} />,
    user: <SidebarUser isSidebar={isSidebar} />,
  };

  return options[access];
}
